<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <div
        class="form-group width100"
      >
        <label>Alert Subscriptions</label>
        <div class="table-scroll">
          <div class="table">
            <div class="row thead">
              <div class="cell label">
                Alerts
              </div>
              <div class="cell checkbox-cell">
                Enabled
                <input
                  id="selectallalerts"
                  type="checkbox"
                  tabindex="0"
                  @click="toggleSelectAll"
                >
              </div>
            </div>
            <div
              v-for="(item,index) in alertList"
              :key="index"
              class="row"
            >
              <div class="cell label">
                <label>{{ item.faultDescription }}</label>
              </div>
              <div class="cell checkbox-cell">
                <b-form-checkbox
                  :key="item.faultCode"
                  v-model="subscriptions"
                  :value="item.faultCode"
                  name="flavour-4a"
                  inline
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="form-group width100"
      >
        <label>Notification Preferences</label>
        <div class="table">
          <div class="row thead">
            <div class="cell label">
              Preferences
            </div>
            <div class="cell checkbox-cell">
              Enabled
            </div>
          </div>
          <div
            v-for="(item,index) in contactOptionsOptions"
            :key="index"
            class="row"
          >
            <div class="cell label">
              <label>{{ item }}</label>
            </div>
            <div class="cell checkbox-cell">
              <input
                v-model="contactOptions"
                type="checkbox"
                tabindex="0"
                :name="item"
                :value="item"
              >
            </div>
          </div>
        </div>
      </div>
    </b-form>

    <!-- PERMISSION TABLE
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const contactOptionsOptions = ['email'] // ['email', 'teams', 'push', 'telegram']
    const contactOptions = ref([])
    const alertList = ref([])
    const subscriptions = ref([])

    onMounted(() => {
      if (props.userData.alert_notification_preferences) {
        if (props.userData.alert_notification_preferences.email) {
          contactOptions.value.push('email')
        }
        if (props.userData.alert_notification_preferences.teams) {
          contactOptions.value.push('teams')
        }
        if (props.userData.alert_notification_preferences.push) {
          contactOptions.value.push('push')
        }
        if (props.userData.alert_notification_preferences.telegram) {
          contactOptions.value.push('telegram')
        }
      }
      if (props.userData.alertSubscription) {
        subscriptions.value = props.userData.alertSubscription ? props.userData.alertSubscription : []
      }
      console.log(subscriptions.value)
    })

    const getFaultCodes = () => {
      store.dispatch('app-user/fetchFaultCodes')
        .then(response => {
          alertList.value = response.data
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in retrieving fault codes list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateUser = () => {
      const notificationPreferences = {}
      const contactOptionsArray = ['email', 'teams', 'push', 'telegram']
      contactOptionsArray.forEach(option => {
        if (contactOptions.value.includes(option)) {
          notificationPreferences[option] = true
        } else {
          notificationPreferences[option] = false
        }
      })
      const updatedUser = {
        userObjectID: props.userData.userObjectID,
        alert_notification_preferences: notificationPreferences,
        alert_subscription: subscriptions.value,
      }
      console.log(updatedUser)
      store.dispatch('app-user/updateNotificationPreferences', updatedUser)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User notification preferences updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Updating User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    getFaultCodes()

    const toggleSelectAll = () => {
      const selectAll = document.getElementById('selectallalerts')
      console.log(selectAll.checked)
      if (selectAll.checked) {
        subscriptions.value = [...alertList.value.map(alert => alert.faultCode)]
      } else {
        subscriptions.value = []
      }
    }

    return {
      updateUser,
      toggleSelectAll,
      alertList,
      contactOptionsOptions,
      contactOptions,
      subscriptions,
    }
  },
}
</script>
<style scoped>
    .table {
        width: 100%;
        display: table;
    }
    .body{
        display: table-row-group;
    }
    .row{
        display: table-row;
    }
    .thead{
        position: -webkit-sticky;
        position: sticky;
        top: -1px;
    }
    .thead .cell{
        font-weight: bold;
        background-color: #a3a3a3;
        text-align: center;
    }
    .cell{
        display: table-cell;
        border: 1px solid #dddddd;
        padding: 8px;
        vertical-align: top;
    }
    .checkbox-cell{
        text-align: center;
    }
    .checkbox-cell input[type=checkbox] {
        cursor: pointer;
        width: 17px;
        height: 17px;
    }
    .cell label{
        font-size: 1em !important;
        text-transform: capitalize;
    }
    .table-scroll{
        overflow: auto;
        max-height: 65vh;
    }
</style>
