<template>
  <div>

    <!-- Media -->
    <!-- <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4>
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media> -->

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Phone"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="userData.phoneNumber"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Customer"
            label-for="customerID"
            :state="userData.customerID ? true : false"
          >
            <v-select
              v-model="userData.customerID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerList"
              label="name"
              :reduce="val => val.customerID"
              :clearable="false"
              input-id="customerID"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Contact Options -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Site"
            label-for="siteID"
          >
            <v-select
              v-model="userData.sites"
              multiple
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="siteList"
              label="name"
              :reduce="val => val.id"
              :clearable="false"
              input-id="siteID"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import customer from '@/store/customer'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customer)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()
    const contactOptionsOptions = ['email', 'whatsapp', 'push', 'telegram']
    const customerList = ref([])
    const siteList = ref([])
    const roleOptions = [
      { label: 'Super Admin', value: 'superAdmin' },
      { label: 'Admin', value: 'admin' },
      { label: 'User', value: 'user' },
      { label: 'Maintainer', value: 'maintainer' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const fetchAllCustomers = () => {
      store.dispatch('app-customer/fetchAllCustomers')
        .then(response => {
          customerList.value = response.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Customers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchAllSites = () => {
      store.dispatch('factory/fetchAllSites')
        .then(response => {
          const { data } = response
          if (props.userData.customerID) {
            siteList.value = data.filter(site => site.customerID === props.userData.customerID)
          } else {
            siteList.value = data
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Fetching Sites',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetchAllCustomers()
    fetchAllSites()

    const updateUser = () => {
      const updatedUser = {
        email: props.userData.email,
        userObjectID: props.userData.userObjectID,
        display_name: props.userData.name,
        customer_id: props.userData.customerID,
        role: props.userData.role,
        phone_number: props.userData.phoneNumber,
        sites: props.userData.sites,
        addresses: props.userData.addresses,
        areas: props.userData.areas,
        team: props.userData.team,
        view_preferences: props.userData.viewPreferences,
        notification_preferences: props.userData.notificationPreferences,
        alert_subscription: props.userData.alertSubscription,
        customer_subscription: props.userData.customerNotificationSubscriptions,
        shift_config: props.userData.shiftConfig,
      }
      store.dispatch('app-user/updateUser', updatedUser)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Customer Updated Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error in Updating Customer',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      customerList,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      contactOptionsOptions,
      updateUser,
      siteList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
